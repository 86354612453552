import React, {useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'

import { useNavigate, Link } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { GoogleLogin } from '@react-oauth/google';
import { sendData } from '../utils';
import ReCAPTCHA from "react-google-recaptcha";
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}; 
const Login = () => {
  const isMobile = useIsMobile();

  const [showalert, setShowalert] = useState(false);
const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recptcha, setIsrecptcha] = useState(null);





  const login = async () => {
    try{
    
      const req = await fetch('https://api.trakitrak.com/merchant/login', {
        method: 'POST',
        headers: {
        //  'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, password})
      });
      const res = await req.json();
      if(req.status === 200){
        localStorage.setItem("token", res.token);
        localStorage.setItem("userid", res.userid);
        //navigate('/home', {replace: true});
        //navigate('/otp')
        localStorage.setItem("hasstore", !res.has_store?'no':'yes');
        if(!res.has_store){
           navigate('/stores/add', {replace: true});

        }else{
          navigate('/home', {replace: true});
        }
      } else if(req.status === 498) {
        localStorage.removeItem("token");
        navigate('/login', {replace: true});


    } else if(req.status==400) {


      if(res.message=='Unverified'){
        setShowalert(true)
        setMsg(res.message)
        console.log({res})
        localStorage.setItem("token", res.token);
        localStorage.setItem("userid", res.userid);
        navigate('/otp')
   
      }
      setShowalert(true)
      setMsg(res.message)
          setError(res.message);
          setLoading(false);
      }
    } catch(err) {
      console.log(err);
      setError("Server Error");
      setLoading(false);

    }
  }

  // const login = async () => {
  //   const {status, data, msg} = await sendData('/login', JSON.stringify({email, password}), navigate);

  //   if(status){
  //     localStorage.setItem("token", data.token);
  //     localStorage.setItem("userid", data.userid);
  //     //navigate('/home', {replace: true});
  //     //navigate('/otp')
  //     localStorage.setItem("hasstore", !data.has_store?'no':'yes');
  //     if(!data.has_store){
  //        navigate('/stores/add', {replace: true});
  //     }else{
  //       navigate('/home', {replace: true});
  //     }
      

  //   }else{
  //     setShowalert(true)
  //     setMsg(msg)
  //     if(msg=='Unverified'){
  //       console.log(data)
  //       // localStorage.setItem("token", data.token);
  //       // localStorage.setItem("userid", data.userid);
  //       navigate('/otp')
  //     }
      
  //   }
  // }
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'warning'} variant={'warning'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  const googleSignin = async (credentials) => {
    const {status, data, msg} = await sendData('/sociallogin', JSON.stringify({credentials:credentials}),);
    if(status){
 localStorage.setItem("token", data.access_token);
 localStorage.setItem("userid", data.userid);
 localStorage.setItem("hasstore", !data.has_store?'no':'yes');
 if(!data.has_store){
    navigate('/stores/add', {replace: true});
 }else{
   navigate('/home', {replace: true});
 }
    console.log({data})
    }else{
      setShowalert(true)
      setMsg(msg)
    }

  }

  return (
 <div style={{backgroundColor:'white'}}>
 {showAlerts()}

    <div 
    className='login-container'
    >  
    <div  className='first-div'>
          <img src={'./merchant.png'} alt='trakitrak shoppers logo'  style={{height:'15%',width:'40%', objectFit:'contain' }} />
    <div style={{ width: '99%', height: 3, background: 'linear-gradient(to right, #ffffff, #e05b0e)', alignSelf: 'center',marginTop:'2%'}}></div>
    <div style={{ width: '99%', height: 250, background: 'linear-gradient(to right, #ffffff, #e05b0e)', alignSelf: 'center',marginTop:'2%'}}>
    <img src={'./loginn.jpg'}  style={{height:'100%',width:'100%', objectFit:'cover' }} />

    </div>

  </div>
  <div  className='first-div1'>
          <img src={'./merchant.png'} alt='trakitrak shoppers logo'  style={{height:'50%',width:'100%', objectFit:'contain' }} />
   

  </div>
      <div 
      className='login-small'
      >
      <div 
      style={{width:'100%',height:400,border:'none',display:'flex',justifyContent:'center'}}
      className="card" 
      >

        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
            <div className="mt-3s" style={{width: '90%'}}>
                <label>Email</label>
                <input onChange={(e) => setEmail(e.target.value)} className="form-control w-100" type="email" />
            </div>

            <div className="mt-3" style={{width: '90%'}}>
                <label>Password</label>
                <input onChange={(e) => setPassword(e.target.value)} className="form-control w-100" type="password" />
            </div>
            <div style={{width: '90%',marginTop:10}}>
            <a style={{textDecoration:'underline',fontSize:13,color:'black',alignSelf:'flex-start',width:'75%',}}
            onClick={()=>navigate("/reset-password")}>Reset password</a>
            </div>

            <div style={{paddingTop:10,alignSelf:'flex-start',marginLeft:'5%'}}>
            <ReCAPTCHA
            sitekey="6LefKnsqAAAAAOop0UL1020jcGRnDW6DW4JP7ERe"
            onChange={(e)=>{setIsrecptcha(e)}}
              />
            </div>

            <div style={{cursor:recptcha?'pointer':'not-allowed',width:isMobile?'90%':null}}    className={'loginbtn'} onClick={recptcha?login:null}>Log in</div>
            {isMobile ? (
  <p style={{ color: 'black', fontWeight: '500' }}>
    Don't Have Account?{' '}
    <a href="/register" style={{ color: '#e05b0e', textDecoration: 'none' }}>
      Sign Up
    </a>
  </p>
) : null}           {!isMobile&&(
             <div style={{width: '90%',marginTop:10}}>
             <p style={{color:'black',alignSelf:'flex-start',width:'75%',fontSize:14}}>Log in with:</p>
             <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'3%'}}>
            <GoogleLogin
              shape='circle'
              theme='outline'
              type='icon'
              size='large'
 
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
                googleSignin(credentialResponse.credential);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
            <p style={{margin:0,marginLeft:5,fontSize:14}}>Google</p>
          </div> 
             </div>
           )}
        </div>

    </div>
      </div>
    </div>
 </div>
  
  )
}

export default Login