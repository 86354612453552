import React, {useMemo, useState,useEffect} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { hasJWT } from "./AuthedRoute";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import inv from '../inv.png'
import bag from '../bag.png'
import user from '../user.png'
import groc from '../groc.png'
import stat from '../stat.png'
import store from '../store.png'
import invred from '../invred.png'
import bagred from '../bagred.png'
import userred from '../userred.png'
import grocred from '../grocred.png'
import statred from '../statred.png'
import storered from '../storered.png'

import { fetchData, sendData } from "../utils";
import { us } from "@mapbox/mapbox-gl-geocoder/lib/exceptions";
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return isMobile;
  }; 
const ProtectedRoute = ({ component: Component, ...params }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const location = useLocation();
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);
    const [storename, setStorename] = useState(false);
    //const [hasstore, setHasstore] = useState(true);
    const { hash, pathname, search } = location;

    let hasstore = localStorage.getItem('hasstore') == 'yes';
    console.log({hasstore})

    useEffect(()=>{
        setTimeout(() => {
        
            let name = localStorage.getItem('storename')
            let id = localStorage.getItem('storeid')
            window.Intercom('update')
            window.Intercom('update', {"name": name,"id":id});
        }, 150);
      },[]);

    const fetchStores= async (signal)=>{
        console.log('fetching stores')
        const {status, data, msg} = await fetchData(`/stores/${localStorage.getItem('userid')}`);
if(status){
 
    if(data.length>0){
        console.log('fetching stores12')
        console.log(data)
        setStorename(data[0].name)
        localStorage.setItem('storeid',data[0].id)
        localStorage.setItem('storename',data[0].name)
        setShow(!show)
    }

}else{
    console.log(msg)
}
       
        }
        const fetchOrders = async () => {
            if(localStorage.getItem('storeid')){
    
            const {status, data, msg} = await sendData('/orders', JSON.stringify({status:'Waiting',from:'',to:'', branchid:'0', storeid: localStorage.getItem('storeid'),search:'',page:1}));
            if(status){
                const currentTime = new Date(); 
                setCount((data.orders).length)
                const hasBeginnOne = data.orders.some(order => order.beginn === 1);
                const hasBeginnZero = data.orders.some(order => order.beginn === 0);
                let nearestBegin = null;
          
                if(hasBeginnOne){
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage('play-sound');
                      } else {
                        console.error('ReactNativeWebView is not defined');
                      }        
                                        playSound()
                }else if(hasBeginnZero){
                  const filteredOrders = data.orders.filter(order => order.beginn === 0);
                  for (const order of filteredOrders) {
                    const begintime = new Date(order.begintime.replace(" ", "T")); // Ensure correct ISO format
                    const productsCount = order.cart5s?.[0]?.products_count || 0; // Handle missing or empty cart5s
                    const cartTimeOffset = 35 + (productsCount * 2); // Offset in minutes
                    const adjustedTime = new Date(currentTime.getTime() + cartTimeOffset * 60000); // Adjust current time
          
          
                    // Compare adjusted time and begintime
                    if (adjustedTime.getTime() >= begintime.getTime()) {
                        nearestBegin = order;
                        break;
                    }
                }
              
                if(nearestBegin){
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage('play-sound');
                      } else {
                        console.error('ReactNativeWebView is not defined');
                      }        
                                playSound()
                }
                
                }
            }else{
            //   setShowalert(true)
            //   setMsg(msg)
            //   setOrders([]);
            //   setLoading(false);
            //   setFirstFetch(true)
            }
          }else{
            // setLoading(false)
          }
          }
          useEffect(() => {
            // Call fetchOrders initially
            fetchOrders();
          
            // Set up the interval to call fetchOrders every 60 seconds
            const interval = setInterval(() => {
              fetchOrders();
            }, 60000); // 60000 ms = 60 seconds
          
            // Clean up the interval on component unmount
            return () => clearInterval(interval);
          }, []);
      useEffect(()=>{
        if(localStorage.getItem('storeid')){
         console.log('test')
         window.Intercom('update')
         setShow(!show)
        }else{
      setTimeout(() => {
        fetchStores()
      }, 330);
            window.Intercom('update')
            setShow(!show)
        }
       
      },[])
      const [navbarExpanded, setNavbarExpanded] = useState(false);
      const [isscroll, setiscroll] = useState(true);
      const playSound = () => {
        const audio = new Audio('/audionew.mp3'); // Path to your sound file
       // audio.volume = 1.0; // Set volume (0.0 to 1.0)
       audio.play().catch((error) => {
        console.error('Audio play failed:', error);
      });
      
      }
    if (hasJWT()) return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>


            {/* //! HEADER */}
            <Navbar onClick={()=>setNavbarExpanded(!navbarExpanded)} collapseOnSelect expand='lg' 
            expanded={navbarExpanded} // Control the expanded state
            
            className="nav-auth-shadow">
                <Container fluid={true} style={{padding:isMobile?'0 2% 0 7%': '0 2% 0 5%' }}>
                    {isMobile&&(
                     <div
                         onClick={() => navigate(-1)} // Navigate back when the arrow is clicked
                         style={{
                             display: 'flex',
                             position:'absolute',
                             marginLeft:'-6%',
                             marginTop:'-3%',
                             // alignItems: 'center',
                             cursor: 'pointer',
                             // marginRight: '10px',
                         }} >
                             <svg
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                             >
                                 <path
                                 d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
                                 stroke="#e05b0e"
                                 strokeWidth="1.5"
                                 strokeMiterlimit="10"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 />
                             </svg>
                     </div>
                    )}
      
                    <Navbar.Brand  onClick={()=>{navigate("/")
                        setNavbarExpanded(!navbarExpanded)}}  style={{fontSize: 30,marginLeft:isMobile?'5%':0}}>
                        <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/merchant.png'}  className="brandimage"/>
                        </Navbar.Brand>
                        <div style={{marginRight:isMobile?15:50,position:'absolute',right:'6%'}}>
                <svg height={isMobile?'15pt':'17pt'} viewBox="-21 0 512 512" width={isMobile?'15pt':'17pt'} xmlns="http://www.w3.org/2000/svg" id="fi_1827422">
                    <path d="m453.332031 229.332031c-8.832031 0-16-7.167969-16-16 0-61.269531-23.847656-118.847656-67.15625-162.175781-6.25-6.25-6.25-16.382812 0-22.632812s16.382813-6.25 22.636719 0c49.34375 49.363281 76.519531 115.007812 76.519531 184.808593 0 8.832031-7.167969 16-16 16zm0 0"></path>
                    <path d="m16 229.332031c-8.832031 0-16-7.167969-16-16 0-69.800781 27.179688-135.445312 76.542969-184.789062 6.25-6.25 16.386719-6.25 22.636719 0s6.25 16.386719 0 22.636719c-43.328126 43.304687-67.179688 100.882812-67.179688 162.152343 0 8.832031-7.167969 16-16 16zm0 0"></path>
                    <path d="m234.667969 512c-44.117188 0-80-35.882812-80-80 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 26.476562 21.523437 48 48 48 26.472656 0 48-21.523438 48-48 0-8.832031 7.167969-16 16-16s16 7.167969 16 16c0 44.117188-35.882813 80-80 80zm0 0"></path>
                    <path d="m410.667969 448h-352c-20.589844 0-37.335938-16.746094-37.335938-37.332031 0-10.925781 4.757813-21.269531 13.058594-28.375 32.445313-27.414063 50.941406-67.261719 50.941406-109.480469v-59.480469c0-82.34375 66.988281-149.332031 149.335938-149.332031 82.34375 0 149.332031 66.988281 149.332031 149.332031v59.480469c0 42.21875 18.496094 82.066406 50.730469 109.332031 8.511719 7.253907 13.269531 17.597657 13.269531 28.523438 0 20.585937-16.746094 37.332031-37.332031 37.332031zm-176-352c-64.707031 0-117.335938 52.628906-117.335938 117.332031v59.480469c0 51.644531-22.632812 100.414062-62.078125 133.757812-.746094.640626-1.921875 1.964844-1.921875 4.097657 0 2.898437 2.433594 5.332031 5.335938 5.332031h352c2.898437 0 5.332031-2.433594 5.332031-5.332031 0-2.132813-1.171875-3.457031-1.878906-4.054688-39.488282-33.386719-62.121094-82.15625-62.121094-133.800781v-59.480469c0-64.703125-52.628906-117.332031-117.332031-117.332031zm0 0"></path>
                    <path d="m234.667969 96c-8.832031 0-16-7.167969-16-16v-64c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v64c0 8.832031-7.167969 16-16 16zm0 0"></path>
               </svg>
                   {isMobile&&count?
                    <div style={{width:15,height:15,borderRadius:15,backgroundColor:'red',right:'4%',position:'absolute',top:'35%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <p style={{color:'#fff',fontSize:8}}>{count}</p>
                    </div>
                   :
                   count?
                   <div style={{width:20,height:20,borderRadius:20,backgroundColor:'red',right:'5%',position:'absolute',top:'28%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <p style={{color:'#fff',fontSize:11}}>{count}</p>
                   </div>:
                   null}
                </div>
                  {!isMobile&&(
                    <>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                       {hasstore&&(
                         <Nav className="subnav">
                         <NavDropdown   title={
                                 <span style={{color:(pathname=='/stores'||pathname=='/stores/add' )?'red':'black'}}>{storename}</span>
                             } renderMenuOnMount={true}  id="collapsible-nav-dropdown2" className="tt-protected-dropdown">
                                 <NavDropdown.Item onClick={()=>{navigate('/stores')
                                    setNavbarExpanded(!navbarExpanded)}}>My Stores</NavDropdown.Item>
                                 <NavDropdown.Item onClick={()=>{navigate('/stores/add',{replace:true})
                                 setNavbarExpanded(!navbarExpanded)}}>Add Store</NavDropdown.Item>
                             </NavDropdown>
                           
                             <Nav.Link style={{color:pathname==`/stores/${localStorage.getItem('storeid')}`?'red':'black',}} onClick={()=>{

                                 if(localStorage.getItem('storeid')){
                                     navigate(`/stores/${localStorage.getItem('storeid')}`)
                                 }else{
                                     fetchStores()
 
                                 }
                                 setNavbarExpanded(!navbarExpanded)
                             }} >Store</Nav.Link>
 
                             <Nav.Link  style={{color:pathname == '/products'?'red':'black',}}onClick={()=>{navigate('/products',)
                                setNavbarExpanded(!navbarExpanded)}}>Products</Nav.Link>
                             <Nav.Link style={{color:pathname == '/orders'?'red':'black',}} onClick={()=>{navigate('/orders',)
                                setNavbarExpanded(!navbarExpanded)}}>Orders</Nav.Link>
                             <Nav.Link style={{color:pathname.includes('/reports')?'red':'black',}} onClick={()=>{navigate('/reports/products-not-found',)
                                setNavbarExpanded(!navbarExpanded)}}>Reports</Nav.Link>
                             {!isMobile&&(
                                <Nav.Link style={{color:pathname.includes('/users')?'red':'black',}} onClick={()=>{navigate('/users',)
                                    setNavbarExpanded(!navbarExpanded)}} >Users</Nav.Link>
                             )}
                             <NavDropdown   renderMenuOnMount={true}  color="red"
                              title={
                                 <span style={{color:(pathname.includes('/payments') || pathname.includes('/bankaccount') || pathname.includes('/billinginformation')||pathname.includes('/payments-settings'))?'red':'black'}}>Billing</span>
                                                             }
                                     style={{ color: 'red' }} // Change this line to set the title color
                                     id="collapsible-nav-dropdown3" className="tt-protected-dropdown">
                                 <NavDropdown.Item  onClick={()=>{navigate('/payments',)
                                    setNavbarExpanded(!navbarExpanded)}}>Settlement</NavDropdown.Item>
                                 <NavDropdown style={{marginLeft:10}} renderMenuOnMount={false} drop={'end'} title={'Billing info'} id="collapsible-nav-dropdown2" >
                                 <NavDropdown.Item  onClick={()=>{navigate('/bankaccount',)
                                    setNavbarExpanded(!navbarExpanded)}} >Bank accounts</NavDropdown.Item>
                                 <NavDropdown.Item onClick={()=>{navigate('/billinginformation',)
                                    setNavbarExpanded(!navbarExpanded)}}>Payment information</NavDropdown.Item>
                                 <NavDropdown.Item onClick={()=>{navigate('/payments-settings',)
                                    setNavbarExpanded(!navbarExpanded)}} >Payment settings</NavDropdown.Item>
                             </NavDropdown>
                             </NavDropdown>
                          </Nav>
                       )}
                            <NavDropdown renderMenuOnMount={true} title="Account" id="collapsible-nav-dropdown" className="tt-protected-dropdown">
                                <NavDropdown.Item href="https://help-stores.trakitrak.com/en/" target="_blank" rel="noopener noreferrer">
                                <div style={{display:'flex'}}>
                                <svg style={{marginRight:10}}version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#a1a1a1" stroke="none">
<path d="M2315 5109 c-597 -61 -1141 -318 -1563 -739 -1002 -1000 -1002 -2620
0 -3620 406 -405 901 -648 1493 -732 119 -17 512 -16 635 1 580 80 1089 330
1489 732 464 467 723 1061 748 1719 26 711 -242 1393 -749 1900 -393 394 -891
643 -1453 726 -122 18 -477 26 -600 13z m390 -1280 c469 -79 674 -474 444
-855 -57 -94 -134 -179 -255 -280 -67 -56 -129 -118 -149 -148 -43 -64 -65
-148 -65 -246 0 -138 -49 -184 -194 -184 -101 0 -140 18 -167 77 -26 59 -26
235 0 339 39 153 100 236 276 378 172 139 214 196 223 306 12 158 -118 254
-330 242 -135 -7 -197 -43 -283 -164 -73 -101 -153 -119 -254 -56 -128 79
-109 268 42 416 159 156 429 223 712 175z m-111 -1974 c88 -42 145 -131 146
-224 0 -83 -19 -130 -75 -188 -104 -107 -260 -108 -362 -1 -130 136 -77 360
102 426 43 16 143 9 189 -13z"/>
</g>
</svg>
<p style={{color:'black'}}>Help</p>
                                  </div>
                           

                                    
                                    </NavDropdown.Item>
                                <NavDropdown.Item href="https://help-stores.trakitrak.com/en/articles/8476862-terms-and-conditions-for-stores-lebanon" target="_blank" rel="noopener noreferrer">
                                    <div style={{display:'flex'}}>
                                    <svg style={{marginRight:10}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#a1a1a1" stroke="none">
<path d="M765 4514 c-5 -1 -22 -5 -37 -8 -41 -9 -95 -61 -113 -109 -14 -38
-15 -183 -13 -1459 l3 -1416 31 -39 c55 -68 79 -76 250 -84 177 -9 226 -14
389 -40 440 -72 821 -252 1098 -518 l47 -45 0 1439 c0 792 -4 1457 -9 1478
-24 102 -259 362 -431 477 -255 169 -581 279 -920 309 -129 12 -280 19 -295
15z"/>
<path d="M4185 4510 c-420 -25 -755 -128 -1045 -321 -172 -113 -407 -374 -431
-476 -5 -21 -9 -686 -9 -1477 l0 -1439 53 49 c285 268 653 441 1092 513 163
26 212 31 389 40 171 8 195 16 250 84 l31 39 3 1411 c2 985 0 1423 -8 1449
-12 44 -52 93 -89 112 -40 21 -94 24 -236 16z"/>
<path d="M105 3861 c-48 -22 -69 -44 -90 -94 -13 -32 -15 -208 -15 -1522 0
-1614 -3 -1523 53 -1583 54 -59 113 -66 269 -33 597 125 1225 142 1703 46 65
-13 121 -23 123 -21 6 7 -95 90 -178 145 -207 138 -474 236 -783 287 -48 8
-183 20 -300 28 -130 9 -228 20 -254 30 -140 50 -250 165 -293 305 -19 61 -20
99 -20 1247 l0 1184 -87 0 c-64 0 -99 -6 -128 -19z"/>
<path d="M4800 2696 c0 -1148 -1 -1186 -20 -1247 -43 -140 -153 -255 -293
-305 -26 -10 -122 -21 -252 -30 -115 -7 -241 -18 -280 -24 -310 -48 -597 -152
-805 -291 -82 -55 -184 -138 -178 -145 2 -2 58 8 123 21 478 96 1106 79 1703
-46 156 -33 215 -26 269 33 56 60 53 -31 53 1585 0 1422 -1 1490 -19 1528 -36
78 -91 105 -216 105 l-85 0 0 -1184z"/>
</g>
</svg>
                                         <p style={{color:'black'}}>Terms & Conditions</p>
                                    </div>
                                   
                                    </NavDropdown.Item>

                                <NavDropdown.Item onClick={()=>{
                                    
                                    localStorage.removeItem('userid')
                                    localStorage.removeItem('storeid')
                                    localStorage.removeItem('storename')
                                    localStorage.removeItem('token')
                                    
                                navigate('/login',{replace:true})}}>
                                        <div style={{display:'flex'}}>
                                        <svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" version="1.0" width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#a1a1a1" stroke="none">
<path d="M325 5106 c-148 -37 -271 -159 -311 -310 -11 -42 -14 -384 -14 -2021 0 -2157 -3 -2031 57 -2135 37 -62 111 -134 169 -163 27 -13 350 -125 719 -248 l670 -224 90 0 c110 0 172 20 255 81 67 49 121 123 150 207 16 46 20 82 20 202 l0 145 353 0 c402 0 442 5 577 71 162 79 293 244 334 419 23 97 23 1033 1 1088 -67 160 -299 170 -383 17 -15 -26 -18 -89 -22 -520 l-5 -490 -25 -45 c-14 -24 -45 -58 -68 -75 l-44 -30 -359 -3 -359 -3 -2 1653 -3 1653 -32 67 c-52 110 -122 175 -242 225 l-56 23 527 -3 526 -2 44 -30 c23 -17 54 -51 68 -75 l25 -45 5 -387 5 -386 30 -43 c45 -64 104 -91 186 -87 76 4 127 32 169 95 l25 37 3 397 c3 448 0 475 -66 609 -54 110 -168 223 -282 279 -152 74 -81 71 -1455 70 -943 -1 -1242 -4 -1280 -13z"/>
<path d="M3965 4031 c-51 -23 -101 -79 -114 -128 -7 -23 -11 -169 -11 -370 l0 -333 -448 0 -448 0 -53 -26 c-90 -45 -134 -140 -110 -238 16 -67 85 -138 151 -154 32 -8 181 -12 477 -12 l431 0 0 -331 c0 -234 4 -344 12 -373 25 -81 118 -146 209 -146 80 1 107 24 567 483 461 460 492 497 492 575 0 95 6 88 -479 575 -301 301 -470 464 -497 477 -51 25 -126 25 -179 1z"/>
</g>
</svg>
                                        <p style={{color:'black'}}>Logout</p>
                                    </div>
                                    </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    </>
                  )}
                </Container>
             
            </Navbar>

         

            {hasstore&&(
                 <Navbar  className="bg-body-tertiary nav-auth-shadow secondnav">
                <Container  fluid={true} style={{padding: '0 10%'}}> 
                    <Navbar.Toggle aria-controls="responsive-navbar-nav2" />
                        <Nav>
                            <NavDropdown   title={
                                <span style={{color:(pathname=='/stores'||pathname=='/stores/add' )?'red':'black'}}>{localStorage.getItem('storename')}</span>
                            } renderMenuOnMount={true}  id="collapsible-nav-dropdown2" className="tt-protected-dropdown">
                                <NavDropdown.Item onClick={()=>navigate('/stores')}>My Stores</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>navigate('/stores/add',{replace:true})}>Add Store</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link style={{color:pathname==`/stores/${localStorage.getItem('storeid')}`?'red':'black',}} onClick={()=>{
                                if(localStorage.getItem('storeid')){
                                    navigate(`/stores/${localStorage.getItem('storeid')}`)
                                }else{
                                    fetchStores()

                                }
                            }} >
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={pathname==`/stores/${localStorage.getItem('storeid')}`?storered:store} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                    Store
                                </div>
                          
                                </Nav.Link>
                            <Nav.Link  style={{color:pathname == '/products'?'red':'black',}}onClick={()=>navigate('/products',)}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={pathname == '/products'?grocred:groc} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                    Products
                             </div>
                            </Nav.Link>
                            <Nav.Link style={{color:pathname == '/orders'?'red':'black',}} onClick={()=>navigate('/orders',)}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={pathname == '/orders'?bagred:bag} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                    Orders
                                </div>
                                </Nav.Link>
                            <Nav.Link style={{color:pathname.includes('/reports')?'red':'black',}} onClick={()=>navigate('/reports/products-not-found')}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img src={pathname.includes('/reports')?statred:stat} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                Reports
                                </div>
                                
                            </Nav.Link>
                           {!isMobile&&(
                             <Nav.Link style={{color:pathname.includes('/users')?'red':'black',}} onClick={()=>navigate('/users',)} >
                             <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                 <img src={pathname.includes('/users')?userred:user} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>
 
                                 Users
                                 </div>
                                 
                                 </Nav.Link>
                           )}
                            <NavDropdown   renderMenuOnMount={true}  color="red"
                             title={
                                <span style={{color:(pathname.includes('/payments') || pathname.includes('/bankaccount') || pathname.includes('/billinginformation')||pathname.includes('/payments-settings'))?'red':'black'}}>
                                <img src={(pathname.includes('/payments') || pathname.includes('/bankaccount') || pathname.includes('/billinginformation')||pathname.includes('/payments-settings'))?invred:inv} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                Billing
                                    
                                    </span>
                            }
    style={{ color: 'red' }} // Change this line to set the title color
     id="collapsible-nav-dropdown3" className="tt-protected-dropdown">
                                <NavDropdown.Item  onClick={()=>navigate('/payments',)}>Settlement</NavDropdown.Item>
                                <NavDropdown style={{marginLeft:10}} renderMenuOnMount={false} drop={'end'} title={'Billing info'} id="collapsible-nav-dropdown2" >
                                <NavDropdown.Item  onClick={()=>navigate('/bankaccount',)} >Bank accounts</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>navigate('/billinginformation',)}>Payment information</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>navigate('/payments-settings',)} >Payment settings</NavDropdown.Item>
                            </NavDropdown>
                            </NavDropdown>
                        </Nav>
                </Container>
             </Navbar>
            )}
          

            {/* //! HEADER */}

            {/* //! PAGE */}
           <div style={{marginTop:5}}>
           {isMobile&&(
             <div   
             onMouseEnter={() => setiscroll(true)} // Trigger on hover
      onMouseLeave={() => setiscroll(false)} // Reset on mouse leave
      onTouchEnd={() => setiscroll(true)} // For mobile interaction
      onScroll={() => setiscroll(true)} // Trigger on scroll
             style={{width:'100%'
             ,height:40,marginBottom:1
            //  ,overflow:'scroll'
             ,display:'flex',
            zIndex: 100, // Ensure a high z-index for the dropdown container
            overflow: isscroll?'scroll':'visible', // Allow dropdowns to overflow
            position: 'relative', // Ensure the container creates a stacking context
             alignItems:'center',scrollbarWidth: 'none', msOverflowStyle: 'none', }}>
             <NavDropdown onClick={()=>setiscroll(false)} style={{zIndex:1050,position:'relative',overflow:'visible'}}    title={
                                    <span style={{color:(pathname=='/stores'||pathname=='/stores/add' )?'red':'black',marginLeft:30}}>{localStorage.getItem('storename')}</span>
                                } renderMenuOnMount={true}  id="collapsible-nav-dropdown2" className="tt-protected-dropdown"

                               
                                >
                                    <NavDropdown.Item onClick={()=>{navigate('/stores')
                                        setiscroll(true)}}>My Stores</NavDropdown.Item>
                                    <NavDropdown.Item onClick={()=>{navigate('/stores/add',{replace:true})
                                    setiscroll(true)}}>Add Store</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link  style={{color:pathname==`/stores/${localStorage.getItem('storeid')}`?'red':'black',marginLeft:10}} onClick={()=>{
                                    if(localStorage.getItem('storeid')){
                                        navigate(`/stores/${localStorage.getItem('storeid')}`)
                                    }else{
                                        fetchStores()
    
                                    }
                                }} >
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={pathname==`/stores/${localStorage.getItem('storeid')}`?storered:store} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>
    
                                        Store
                                    </div>
                              
            </Nav.Link>
            <Nav.Link  style={{color:pathname == '/products'?'red':'black',marginLeft:10}}onClick={()=>navigate('/products',)}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={pathname == '/products'?grocred:groc} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>
    
                                        Products
                                 </div>
            </Nav.Link>
            <Nav.Link style={{color:pathname == '/orders'?'red':'black',marginLeft:10}} onClick={()=>navigate('/orders',)}>
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={pathname == '/orders'?bagred:bag} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>
    
                                        Orders
                                    </div>
            </Nav.Link>
            <Nav.Link style={{color:pathname.includes('/reports')?'red':'black',marginLeft:10}} onClick={()=>navigate('/reports/products-not-found')}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={pathname.includes('/reports')?statred:stat} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>
    
                                    Reports
                                    </div>
                                    
            </Nav.Link>
            <NavDropdown onClick={()=>setiscroll(false)} renderMenuOnMount={true}  color="red"
                             title={
                                <span style={{color:(pathname.includes('/payments') || pathname.includes('/bankaccount') || pathname.includes('/billinginformation')||pathname.includes('/payments-settings'))?'red':'black',marginLeft:10}}>
                                <img src={(pathname.includes('/payments') || pathname.includes('/bankaccount') || pathname.includes('/billinginformation')||pathname.includes('/payments-settings'))?invred:inv} style={{width:19,marginRight:5,height:19,objectFit:'contain'}}/>

                                Billing
                                    
                                    </span>
                            }
    style={{ color: 'red',marginRight:15 }} // Change this line to set the title color
     id="collapsible-nav-dropdown3" className="tt-protected-dropdown">
                                <NavDropdown.Item  onClick={()=>{navigate('/payments',)
                                    setiscroll(false)}}>Settlement</NavDropdown.Item>
                                {/* <NavDropdown style={{marginLeft:10}} renderMenuOnMount={false} drop={'end'} title={'Billing info'} id="collapsible-nav-dropdown2" > */}
                                <NavDropdown.Item  onClick={()=>{navigate('/bankaccount',)
                                    setiscroll(false)}} >Bank accounts</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>{navigate('/billinginformation',)
                                    setiscroll(false)}}>Payment information</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>{navigate('/payments-settings',)
                                    setiscroll(false)}} >Payment settings</NavDropdown.Item>
                            {/* </NavDropdown> */}
             </NavDropdown>
          
            </div>
       
           )}
           {!location.pathname.includes("reports")?
            <Container>
                <Component {...params} />
            </Container> : <Component {...params} /> }
           </div>
            {/* //! PAGE */}

        </div>
    );
    
    return <Navigate to={"/login"} replace />;
}

export default ProtectedRoute

