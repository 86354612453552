import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom";
import AuthedRoute from './components/AuthedRoute';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedRoute1 from './components/ProtectedRoute1';
import Login from "./pages/Login";
import Home from "./pages/Home";
import Stores from "./pages/Stores";
import StoreDetail from "./pages/StoreDetail";
import Welcome from "./pages/Welcome";
import Welcome1 from "./pages/Welcome1";
import Deal from "./pages/Deal";
import AddStores from "./pages/AddStores";
import EditBranch from "./pages/EditBranch";
import Products from "./pages/Products";
import AddBranch from "./pages/AddBranch";
import Orders from "./pages/Orders";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import Payments from "./pages/Payments";
import BanksAccount from "./pages/BanksAccount";
import AddBankAccount from "./pages/AddBankAccount";
import EditBankAccount from "./pages/EditBankAccount";
import BillingInformation from "./pages/BillingInformation";
import AddBillingInformation from "./pages/AddBillingInformation";
import EditBillingInformation from "./pages/EditBillingInformation";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import AddProductForm from "./pages/AddProductForm";
import AfterAddStore from "./pages/AfterAddStore";
import IntercomWidget from "./pages/IntercomWidget";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import PaymentsSettings from "./pages/PaymentsSettings";
import { GoogleOAuthProvider } from "@react-oauth/google";
import EditProductsAvailability from "./pages/EditProductsAvailability";
import Terms from "./pages/Terms";
import EditStore from "./pages/EditStore";

import AddProductVariant from "./pages/AddProductVariant";
import AddProductBarcode from "./pages/AddProductBarcode";
import ReportsHeader from "./components/ReportsHeader";
import NotFound from "./pages/reports/NotFound";
import FoundRate from "./pages/reports/FoundRate";
import PopularProducts from "./pages/reports/PopularProducts";
import OrdersReport  from "./pages/reports/Orders";
import OrdersHeatmap from "./pages/reports/OrdersHeatmap";
import SalesByCategory from "./pages/reports/SalesByCategory";
import ProductSales from "./pages/reports/ProductSales";
import ProductsSearched from "./pages/reports/ProductsSearched";
import Otp from "./pages/Otp";
import ExchangeRate from "./pages/ExchangeRate";

const Test = () => (
  <>
    <ReportsHeader />
    <Outlet />
  </>
);


function App() {
;
  return (
    <GoogleOAuthProvider clientId="367005268436-c300cn9gkku6n39c6b9kc7cddcasc4h8.apps.googleusercontent.com">

 <Router>
        <Routes>

          <Route path="/otp" element={<ProtectedRoute1 component={Otp} />}></Route>
          <Route path="/login" element={<AuthedRoute component={Login} />}></Route>
          <Route path="/register" element={<AuthedRoute component={Register} />}></Route>
          <Route path="/reset-password" element={<AuthedRoute component={ResetPassword} />}></Route>

          <Route path="/home" element={<ProtectedRoute component={Home} />}></Route>
          <Route path="/stores" element={<ProtectedRoute component={Stores} />}></Route>
          <Route path="/stores/add"  element={<ProtectedRoute component={Welcome} />}></Route>
          <Route path="/stores/products-agree" element={<ProtectedRoute component={Welcome1} />}></Route>
          <Route path="/stores/deal" element={<ProtectedRoute component={Deal} />}></Route>
          <Route path="/terms" element={<ProtectedRoute component={Terms} />}></Route>
          <Route path="/stores/added" element={<ProtectedRoute component={AfterAddStore} />}></Route>
          <Route path="/stores/edit/:id" element={<ProtectedRoute component={EditStore} />}></Route>

          <Route path="/branch/edit/:id" element={<ProtectedRoute component={EditBranch} />}></Route>
          <Route path="/branch/add" element={<ProtectedRoute component={AddBranch} />}></Route>
          <Route path="/stores/add-store" element={<ProtectedRoute component={AddStores} />}></Route>
          <Route path="/stores/:id" element={<ProtectedRoute component={StoreDetail} />}></Route>
          <Route path="/rate/:id" element={<ProtectedRoute component={ExchangeRate} />}></Route>



          <Route path="/products/branches/:id" element={<ProtectedRoute component={EditProductsAvailability} />}></Route>
          <Route path="/products" element={<ProtectedRoute component={Products} />}></Route>
          <Route path="/products/:id" element={<ProtectedRoute component={Products} />}></Route>
          <Route path="/product/create" element={<ProtectedRoute component={AddProduct} />}></Route>
          <Route path="/product/add" element={<ProtectedRoute component={AddProductForm} />}></Route>
          <Route path="/product/add/variant" element={<ProtectedRoute component={AddProductVariant} />}></Route>
          <Route path="/product/add/barcode/:barcode" element={<ProtectedRoute component={AddProductBarcode} />}></Route>


          <Route path="/orders" element={<ProtectedRoute component={Orders} />}></Route>

          <Route element={<ProtectedRoute component={Test} />}>
            <Route path="/reports/products-not-found" element={<NotFound />} />
            <Route path="/reports/found-rate" element={<FoundRate />} />
            <Route path="/reports/popular-products" element={<PopularProducts />} />
            <Route path="/reports/orders-report" element={<OrdersReport />} />
            <Route path="/reports/orders-heatmap" element={<OrdersHeatmap />} />
            <Route path="/reports/sales-by-category" element={<SalesByCategory />} />
            <Route path="/reports/product-sales" element={<ProductSales />} />
            <Route path="/reports/products-searched" element={<ProductsSearched />} />
          </Route>


          <Route path="/users" element={<ProtectedRoute component={Users} />}></Route>
          <Route path="/users/add" element={<ProtectedRoute component={AddUser} />}></Route>
          <Route path="/users/edit/:id" element={<ProtectedRoute component={EditUser} />}></Route>

          <Route path="/payments" element={<ProtectedRoute component={Payments} />}></Route>
          <Route path="/bankaccount" element={<ProtectedRoute component={BanksAccount} />}></Route>
          <Route path="/bankaccount/add" element={<ProtectedRoute component={AddBankAccount} />}></Route>
          <Route path="/bankaccount/edit/:id" element={<ProtectedRoute component={EditBankAccount} />}></Route>

          <Route path="/billinginformation" element={<ProtectedRoute component={BillingInformation} />}></Route>
          <Route path="/billinginformation/add" element={<ProtectedRoute component={AddBillingInformation} />}></Route>
          <Route path="/billinginformation/edit/:id" element={<ProtectedRoute component={EditBillingInformation} />}></Route>

          <Route path="/payments-settings" element={<ProtectedRoute component={PaymentsSettings} />}></Route>


          <Route path="*" element={<Navigate to="/home" />}></Route>
        </Routes>
        <IntercomWidget />
    </Router>

    </GoogleOAuthProvider>
  );
}

export default App;
