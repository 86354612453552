import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ReactPixel from 'react-facebook-pixel';
import App from './App';
<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6O5POgciakJOn0MmrlKx3vP5PeRZJtVE&libraries=places&callback=logPlaceDetails"></script>
{/* <script dangerouslySetInnerHTML={{
    __html: `
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "nxgjy441"              };
    `,
  }} ></script> */}
//   <script async src="https://widget.intercom.io/widget/nxgjy441" ></script>
const pixelId = '3204237779709880';
ReactPixel.init(pixelId); // Initialize Pixel
ReactPixel.pageView();
const advancedMatching = {}; // Add matching information (like email, phone)
const options = { autoConfig: true, debug: false };
ReactPixel.init(pixelId, advancedMatching, options);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

